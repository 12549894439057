import qs from 'qs';

import {usePreferences} from './hooks/usePreferences';
import {getLog} from './log';
import {Dict, ID} from './model';

type Route = string;
type RoutePattern = string;
type RouteParameter = ID;

type QParam = undefined | string | string[] | ParsedQs | ParsedQs[] | boolean;

interface ParsedQs {
  [key: string]: QParam;
}

const log = getLog('routes', 'INFO');

export const _CREATE = '_create_';
export const _EMPTY = '_';

export const REDIRECT_PATH_PARAM = 'redirectPath';

export const PRINTABLE_SETTLEMENT__customerId_settlementId = '/printable/customer/:customerId/settlement/:settlementId';
export const HOME = '/';
export const LOGIN = '/login';
export const WELCOME = '/welcome';
export const USER = '/user';
export const BRAND__brandId = '/brand/:brandId';
export const PROVISIONING = `${BRAND__brandId}/provisioning`;
export const PROVISIONING_LIST = `${PROVISIONING}/list`;
export const PROVISIONING_CUSTOMER__ = `${PROVISIONING}/customer`;
export const PROVISIONING_CUSTOMER__customerId = `${PROVISIONING_CUSTOMER__}/:customerId`;
export const PROVISIONING_CUSTOMER_OVERVIEW__customerId = `${PROVISIONING_CUSTOMER__customerId}/overview`;
export const PROVISIONING_CUSTOMER_COMPANY__customerId = `${PROVISIONING_CUSTOMER__customerId}/company`;
export const PROVISIONING_CUSTOMER_USERS__customerId = `${PROVISIONING_CUSTOMER__customerId}/users`;
export const PROVISIONING_CUSTOMER_USER__customerId_userId = `${PROVISIONING_CUSTOMER_USERS__customerId}/:userId`;
export const PROVISIONING_CUSTOMER_USER_CREATE__customerId = `${PROVISIONING_CUSTOMER_USERS__customerId}/${_CREATE}`;
export const PROVISIONING_CUSTOMER_CONTRACTS__customerId = `${PROVISIONING_CUSTOMER__customerId}/contracts`;
export const PROVISIONING_CUSTOMER_CONTRACT__customerId_contractId = `${PROVISIONING_CUSTOMER_CONTRACTS__customerId}/:contractId`;
export const PROVISIONING_CUSTOMER_CONTRACT_CREATE__customerId = `${PROVISIONING_CUSTOMER_CONTRACTS__customerId}/${_CREATE}`;
export const PROVISIONING_CUSTOMER_PRODUCTS__customerId = `${PROVISIONING_CUSTOMER__customerId}/products`;
export const PROVISIONING_CUSTOMER_SERVICES__customerId = `${PROVISIONING_CUSTOMER__customerId}/services`;
export const PROVISIONING_CUSTOMER_PRODUCT__customerId_contractId_productId = `${PROVISIONING_CUSTOMER_CONTRACT__customerId_contractId}/products/:productId`;
export const PROVISIONING_CUSTOMER_SERVICE__customerId_contractId_productId = `${PROVISIONING_CUSTOMER_CONTRACT__customerId_contractId}/services/:productId`;
export const PROVISIONING_CUSTOMER_SERVICE_CREATE__customerId_contractId = `${PROVISIONING_CUSTOMER_CONTRACT__customerId_contractId}/services/${_CREATE}`;
export const PROVISIONING_CUSTOMER_CONTACTS__customerId = `${PROVISIONING_CUSTOMER__customerId}/contacts`;
export const PROVISIONING_CUSTOMER_CONTACT__customerId_contactId = `${PROVISIONING_CUSTOMER_CONTACTS__customerId}/:contactId`;
export const PROVISIONING_CUSTOMER_SETTLEMENTS__customerId = `${PROVISIONING_CUSTOMER__customerId}/settlements`;
export const PROVISIONING_CUSTOMER_ACL__customerId = `${PROVISIONING_CUSTOMER__customerId}/acl`;
export const PROVISIONING_CUSTOMER_DOCUMENTS__customerId = `${PROVISIONING_CUSTOMER__customerId}/documents`;
export const PROVISIONING_CUSTOMER_DOCUMENT__customerId_documentId = `${PROVISIONING_CUSTOMER_DOCUMENTS__customerId}/:documentId`;
export const PROVISIONING_CUSTOMER_DOCUMENT_CREATE__customerId = `${PROVISIONING_CUSTOMER_DOCUMENTS__customerId}/${_CREATE}`;
export const PROVISIONING_CUSTOMER_SMPP_CONNECTIONS__customerId = `${PROVISIONING_CUSTOMER__customerId}/smppConnections`;
export const PROVISIONING_PRODUCT__productId = `${PROVISIONING}/product/:productId`;
export const PROVISIONING_SERVICE__productId = `${PROVISIONING}/service/:productId`;
export const SETTLEMENT = `${BRAND__brandId}/settlement`;
export const SETTLEMENT_OVERVIEW = `${SETTLEMENT}/overview`;
export const SETTLEMENT_PENDING_LIST = `${SETTLEMENT}/pendingList`;
export const SETTLEMENT_READY_FOR_APPROVAL_LIST = `${SETTLEMENT}/readyForApprovalList`;
export const SETTLEMENT_SUMMARY = `${SETTLEMENT}/summary`;
export const SETTLEMENT_MONTHLY = `${SETTLEMENT}/monthly`;
export const SETTLEMENT_ARCHIVE = `${SETTLEMENT}/archive`;
export const SETTLEMENT_SEARCH = `${SETTLEMENT}/search`;
export const SETTLEMENT_SEARCH_RESULT = `${SETTLEMENT_SEARCH}/:settlementId`;
export const SETTLEMENT_CUSTOMER_LIST = `${SETTLEMENT}/customerList`;
export const SETTLEMENT_CUSTOMER__ = `${SETTLEMENT}/customer`;
export const SETTLEMENT_CUSTOMER__customerId = `${SETTLEMENT_CUSTOMER__}/:customerId`;
export const SETTLEMENT_CUSTOMER_OVERVIEW__customerId = `${SETTLEMENT_CUSTOMER__customerId}/overview`;
export const SETTLEMENT_CUSTOMER_PENDING__customerId = `${SETTLEMENT_CUSTOMER__customerId}/pendent`;
export const SETTLEMENT_CUSTOMER_APPROVED__customerId = `${SETTLEMENT_CUSTOMER__customerId}/approved`;
export const MANAGEMENT = `${BRAND__brandId}/management`;
export const MANAGEMENT_OVERVIEW = `${MANAGEMENT}/overview`;
export const MANAGEMENT_USERS = `${MANAGEMENT}/users`;
export const MANAGEMENT_USER__userId = `${MANAGEMENT_USERS}/:userId`;
export const MANAGEMENT_PENDING_USERS = `${MANAGEMENT}/pendingUsers`;
export const MANAGEMENT_PENDING_USER__userId = `${MANAGEMENT_PENDING_USERS}/:userId`;
export const MANAGEMENT_NOTIFICATIONS = `${MANAGEMENT}/notifications`;
export const MANAGEMENT_PERMISSIONS = `${MANAGEMENT}/permissions`;
export const MANAGEMENT_SERVICE_CATEGORIES = `${MANAGEMENT}/serviceCategories`;
export const MANAGEMENT_MESSAGE_CHANNELS = `${MANAGEMENT}/messageChannels`;
export const MANAGEMENT_MESSAGE_CHANNELS__messageChannel = `${MANAGEMENT_MESSAGE_CHANNELS}/:messageChannel`;
export const MANAGEMENT_SMSCS = `${MANAGEMENT}/smscs`;
export const MANAGEMENT_SMSCS__smsc = `${MANAGEMENT_SMSCS}/:smsc`;
export const MANAGEMENT_SMPP_CONNECTIONS = `${MANAGEMENT}/smppConnections`;
export const REQUEST_ACCESS = '/requestAccess';
export const REPORT = `${BRAND__brandId}/report`;
export const REPORT_OVERVIEW = `${REPORT}/overview`;
export const REPORT_MONTHLY = `${REPORT}/monthly`;
export const REPORT_STATISTICS = `${REPORT}/statistics`;
export const REPORT_STATISTICS_DELIVERY = `${REPORT_STATISTICS}/delivery`;
export const REPORT_STATISTICS_SENDERID = `${REPORT_STATISTICS}/senderId`;
export const REPORT_MESSAGES = `${REPORT}/messages`;
export const REPORT_MESSAGE__messageId = `${REPORT_MESSAGES}/:messageId`;
export const REPORT_DCB = `${REPORT}/dcb`;
export const REPORT_ACCESS_NUMBER_HISTORY = `${REPORT}/accessNumbersHistory`;
export const REPORT_RESERVED_OAS = `${REPORT}/reservedSenderIds`;
export const DOCUMENTS = '/documents';

export const useSubPath = (parentPath: string) => (absolutePath: string) => absolutePath.replace(parentPath, '');

const pathParameterRegExp = new RegExp(/(:\w+)/);
const pathParameterReplaceReducer = (partialFormattedRoute: RoutePattern, routeParam: RouteParameter) =>
  partialFormattedRoute.replace(pathParameterRegExp, routeParam);

export const format = (routePattern: RoutePattern, ...routeParams: RouteParameter[]) => {
  return routeParams.reduce(pathParameterReplaceReducer, routePattern);
};

export const useBrandFormat = () => {
  const {getPreference} = usePreferences(['brandId']);

  const formatWithBrand = (routePattern: RoutePattern, ...routeParams: RouteParameter[]) => {
    const brandId: string | undefined = getPreference('brandId');
    return brandId
      ? format(routePattern, ...[brandId.toLowerCase(), ...routeParams])
      : format(routePattern, ...routeParams);
  };

  return {
    formatWithBrand,
  };
};

export const appendQuerystring = (route: Route, {...state}: any = {}, alwaysIncludeFields?: string[]) => {
  if (state === null) {
    return route;
  }
  const cleanState: Dict<string> = Object.keys(state)
    .filter(
      (key) =>
        (alwaysIncludeFields && alwaysIncludeFields.includes(key)) ||
        (state[key] &&
          (state[key] === true ||
            (Array.isArray(state[key]) && state[key].length > 0) ||
            (state[key].trim && state[key].trim().length > 0)))
    )
    .sort()
    .reduce((partial: Dict<string>, key) => {
      partial[key] = Array.isArray(state[key]) ? state[key].slice().sort() : state[key];
      return partial;
    }, {});

  const querystring = qs.stringify(cleanState, {arrayFormat: 'brackets'});
  log.debug('appendQuerystring', {alwaysIncludeFields, cleanState, qs: querystring});
  return querystring.length === 0 ? route : route + '?' + querystring;
};

export const parseQuerystring = (querystring: string) => {
  let qsParams = {...qs.parse(querystring && querystring.substring(querystring.indexOf('?') + 1))};
  if (!qsParams) {
    return undefined;
  }
  let params: ParsedQs = {};
  Object.keys(qsParams).forEach((key) => {
    let value: QParam = qsParams[key];
    if (value === 'true') {
      value = true;
    } else if (value === 'false') {
      value = false;
    }
    params[key] = value;
  });
  log.debug('parseQuerystring', params);
  return params;
};

export const join = (...paths: string[]) => paths.join('/');

export const validUnauthorizedPath = (path: string) => [HOME, DOCUMENTS].includes(path);

//  for unit tests:
export const TEST = '/test';
export const TEST_params = `${TEST}/:a/xxx/:b`;
export const TEST_SUBTEST = `${TEST}/subTest`;
