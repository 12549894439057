import {Button} from '@telia/styleguide';
import React, {FC} from 'react';

import {getLog} from '../../log';
import {realHost} from '../../utils/host';
import FormColumn, {FormColumnSizeHalf} from '../common/FormColumn';
import FormRow from '../common/FormRow';
import {ListFc} from '../common/List';
import {ListItemFc} from '../common/ListItem';
import Page from '../common/Page';
import PageSubtitle from '../common/PageSubtitle';
import PageTitle from '../common/PageTitle';
import {DocumentsCommercial} from './DocumentsCommercial';

const log = getLog('DocumentsMain');

export const DocumentsMainFc: FC = (props) => {
  log.debug('render', props);

  const host = realHost();

  return (
    <Page>
      <PageTitle title="Documents" />
      <PageSubtitle subtitle="API guides" />
      <ListFc>
        <>
          <ListItemFc>
            <span className="marginBottom inlineBlock">Bulk SMS Implementation guide (SOAP)</span>
            <FormRow>
              <FormColumn size={FormColumnSizeHalf}>
                <a href={`${host}/documents/api/soap/cpa-api-guide.html`} target="_blank">
                  <Button text="Web" />
                </a>
                <a href={`${host}/documents/api/soap/cpa-api-guide.pdf`} target="_blank">
                  <Button text="PDF" />
                </a>
              </FormColumn>
            </FormRow>
          </ListItemFc>

          <ListItemFc>
            <a href={`${host}/documents/api/rest/bulk.html`} target="_blank">
              <span className="marginBottom inlineBlock">Bulk SMS REST API</span>
            </a>
          </ListItemFc>

          <ListItemFc>
            <a href={`${host}/documents/api/rest/bulk-callback.html`} target="_blank">
              <span className="marginBottom inlineBlock">Bulk SMS Callback REST API</span>
            </a>
          </ListItemFc>
        </>
      </ListFc>
      <DocumentsCommercial />
    </Page>
  );
};
