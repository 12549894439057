import {format} from '@telia/cpa-web-common';
import {Button, FullWidthTable} from '@telia/styleguide';
import classnames from 'classnames';
import qs from 'qs';
import React, {FC, useMemo} from 'react';

import {specialFieldComparators} from '../../../comparators';
import {useOperators} from '../../../hooks/useOperators';
import {getLog} from '../../../log';
import {Dict, ID, PropsWithClassName, ReportSenderIdStatistic, Statistic} from '../../../model';
import Loading from '../../Loading';
import {InformationLineFc} from '../../common/InformationLine';
import MessageStatusDisplayFc from '../../common/MessageStatusDisplay';
import Tooltip from '../../common/Tooltip';

const log = getLog('ReportStatisticsTable', 'INFO');

interface ReportStatisticsTableProps extends PropsWithClassName {
  groupBy: ID[];
  loading: boolean;
  statistics: Statistic[] | ReportSenderIdStatistic[];
}

export const ReportStatisticsTable: FC<ReportStatisticsTableProps> = (props) => {
  const {loading, groupBy, statistics} = props;
  const {getNetType} = useOperators();
  const groupedStatistics = useMemo(() => statisticsGrouping(statistics, groupBy), [statistics, groupBy, getNetType]);

  const [sortedStatistics, getThSortableProps] = FullWidthTable.useThSortable(
    groupedStatistics || [],
    3,
    [],
    specialFieldComparators
  );

  log.debug('sortedStatistics', {sortedStatistics});

  return (
    <React.Fragment>
      <div className={'marginTop'}>
        <FullWidthTable>
          <FullWidthTable.THead>
            <FullWidthTable.Tr>
              {groupBy.includes('date') && (
                <FullWidthTable.ThSortable {...getThSortableProps('date')}>Date</FullWidthTable.ThSortable>
              )}
              {groupBy.includes('month') && (
                <FullWidthTable.ThSortable {...getThSortableProps('month')}>Date</FullWidthTable.ThSortable>
              )}
              {groupBy.includes('customerName') && (
                <FullWidthTable.ThSortable {...getThSortableProps('customerName')} className={'noWrap'}>
                  Customer
                </FullWidthTable.ThSortable>
              )}
              {groupBy.includes('contractId') && (
                <FullWidthTable.ThSortable {...getThSortableProps('contractId')}>Contract</FullWidthTable.ThSortable>
              )}
              {groupBy.includes('senderId') && (
                <FullWidthTable.ThSortable {...getThSortableProps('senderId')} className={'noWrap'}>
                  Sender ID
                </FullWidthTable.ThSortable>
              )}
              {groupBy.includes('accessNumber') && (
                <FullWidthTable.ThSortable {...getThSortableProps('accessNumber')} className={'noWrap'}>
                  Access Nr.
                </FullWidthTable.ThSortable>
              )}
              {groupBy.includes('contractType') && (
                <FullWidthTable.ThSortable {...getThSortableProps('contractType')}>Cnt. Type</FullWidthTable.ThSortable>
              )}
              {groupBy.includes('productType') && (
                <FullWidthTable.ThSortable {...getThSortableProps('productType')} className={'noWrap'}>
                  Service Type
                </FullWidthTable.ThSortable>
              )}
              {groupBy.includes('messageType') && (
                <FullWidthTable.ThSortable {...getThSortableProps('messageType')}>Msg. Type</FullWidthTable.ThSortable>
              )}
              {groupBy.includes('resolution') && (
                <FullWidthTable.ThSortable {...getThSortableProps('resolution')}>Resolution</FullWidthTable.ThSortable>
              )}
              {groupBy.includes('status') && (
                <FullWidthTable.ThSortable {...getThSortableProps('status')}>Status</FullWidthTable.ThSortable>
              )}
              {groupBy.includes('operatorCode') && (
                <FullWidthTable.ThSortable {...getThSortableProps('operatorCode')} className={'noWrap'}>
                  MNO
                </FullWidthTable.ThSortable>
              )}
              {groupBy.includes('netType') && (
                <FullWidthTable.ThSortable {...getThSortableProps('netType')}>Net</FullWidthTable.ThSortable>
              )}
              <FullWidthTable.ThSortable {...getThSortableProps('messages')}>Messages</FullWidthTable.ThSortable>
              <FullWidthTable.ThSortable {...getThSortableProps('segments')}>Segments</FullWidthTable.ThSortable>
            </FullWidthTable.Tr>
          </FullWidthTable.THead>
          <FullWidthTable.TBody>
            {sortedStatistics &&
              sortedStatistics.map((statistic, i) => (
                <FullWidthTable.Tr key={i}>
                  {groupBy.includes('date') && (
                    <FullWidthTable.Td className={'noWrap'}>{statistic.date}</FullWidthTable.Td>
                  )}
                  {groupBy.includes('month') && (
                    <FullWidthTable.Td className={'noWrap'}>{statistic.month}</FullWidthTable.Td>
                  )}
                  {groupBy.includes('customerName') && <FullWidthTable.Td>{statistic.customerName}</FullWidthTable.Td>}
                  {groupBy.includes('contractId') && <FullWidthTable.Td>{statistic.contractId}</FullWidthTable.Td>}
                  {groupBy.includes('senderId') && <FullWidthTable.Td>{statistic.senderId}</FullWidthTable.Td>}
                  {groupBy.includes('accessNumber') && <FullWidthTable.Td>{statistic.accessNumber}</FullWidthTable.Td>}
                  {groupBy.includes('contractType') && <FullWidthTable.Td>{statistic.contractType}</FullWidthTable.Td>}
                  {groupBy.includes('productType') && <FullWidthTable.Td>{statistic.productType}</FullWidthTable.Td>}
                  {groupBy.includes('messageType') && <FullWidthTable.Td>{statistic.messageType}</FullWidthTable.Td>}
                  {groupBy.includes('resolution') && (
                    <FullWidthTable.Td>
                      <span
                        className={classnames(
                          ['Expired', 'Unknown'].includes(statistic.resolution)
                            ? 'greyText'
                            : statistic.resolution === 'Rejected'
                            ? 'redText'
                            : statistic.resolution === 'Delivered'
                            ? 'greenText'
                            : undefined
                        )}
                      >
                        {statistic.resolution}
                      </span>
                      {statistic.resolution === 'Unknown' && (
                        <Tooltip text="Legacy 'MT Sent' statistics data where the resolution is Unknown." />
                      )}
                    </FullWidthTable.Td>
                  )}
                  {groupBy.includes('status') && (
                    <FullWidthTable.Td>
                      <MessageStatusDisplayFc status={statistic.status}></MessageStatusDisplayFc>
                    </FullWidthTable.Td>
                  )}
                  {groupBy.includes('operatorCode') && (
                    <FullWidthTable.Td className={'noWrap'}>{statistic.operatorCode}</FullWidthTable.Td>
                  )}
                  {groupBy.includes('netType') && <FullWidthTable.Td>{statistic.netType}</FullWidthTable.Td>}
                  <FullWidthTable.Td className={'noWrap'}>{format.integer(statistic.messages)}</FullWidthTable.Td>
                  <FullWidthTable.Td className={'noWrap'}>{format.integer(statistic.segments)}</FullWidthTable.Td>
                </FullWidthTable.Tr>
              ))}
          </FullWidthTable.TBody>
        </FullWidthTable>
        {loading && <Loading />}
        {statistics &&
          (statistics.isEmpty() ? (
            <InformationLineFc>No statistics found</InformationLineFc>
          ) : (
            <Button text={'Download as CSV'} onClick={() => downloadAsCsv(sortedStatistics, groupBy)} />
          ))}
      </div>
    </React.Fragment>
  );
};

export const convertToCsv = (statistics: Statistic[], groupBy: ID[]): string => {
  const content = [],
    header = [];
  groupBy.includes('date') && header.push('Date');
  groupBy.includes('month') && header.push('Date');
  groupBy.includes('customerName') && header.push('Customer');
  groupBy.includes('contractId') && header.push('Contract');
  groupBy.includes('accessNumber') && header.push('Access Nr');
  groupBy.includes('contractType') && header.push('Contract Type');
  groupBy.includes('productType') && header.push('Service Type');
  groupBy.includes('messageType') && header.push('Message Type');
  groupBy.includes('resolution') && header.push('Resolution');
  groupBy.includes('status') && header.push('Status');
  groupBy.includes('operatorCode') && header.push('MNO');
  groupBy.includes('netType') && header.push('Net');
  header.push('Messages', 'Segments');
  content.push(header);

  statistics.map((statistic, i) => {
    const statLine = [];
    groupBy.includes('date') && statLine.push(statistic.date);
    groupBy.includes('month') && statLine.push(statistic.month);
    groupBy.includes('customerName') && statLine.push(statistic.customerName);
    groupBy.includes('contractId') && statLine.push(statistic.contractId);
    groupBy.includes('accessNumber') && statLine.push(statistic.accessNumber);
    groupBy.includes('contractType') && statLine.push(statistic.contractType);
    groupBy.includes('productType') && statLine.push(statistic.productType);
    groupBy.includes('messageType') && statLine.push(statistic.messageType);
    groupBy.includes('resolution') && statLine.push(statistic.resolution);
    groupBy.includes('status') && statLine.push(statistic.status);
    groupBy.includes('operatorCode') && statLine.push(statistic.operatorCode);
    groupBy.includes('netType') && statLine.push(statistic.netType);
    statLine.push(0 + statistic.messages, 0 + statistic.segments);
    content.push(statLine);
  });

  return content.map((line) => line.join(';')).join('\r\n');
};

const downloadAsCsv = (statistics: Statistic[], groupBy: ID[]) => {
  const csv = convertToCsv(statistics, groupBy);
  const filename = 'report.csv';
  const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
  const link = document.createElement('a');
  if (link.download !== undefined) {
    // support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const statisticsGrouping = (
  statistics: Statistic[] | ReportSenderIdStatistic[],
  groupBy: ID[]
): Partial<Statistic | ReportSenderIdStatistic>[] => {
  const statsFields = ['messages', 'segments', ...groupBy];
  statsFields.includes('month') && statsFields.remove('date');
  log.debug('statsFields', statsFields);

  const simplifiedStats = statistics.clone().map(({...s}) => {
    Object.keys(s)
      .filter((f) => !statsFields.includes(f))
      .forEach((f) => delete s[f as keyof typeof s]);

    return s;
  });
  log.debug('simplifiedStats', simplifiedStats);

  const groupedStatistics = simplifiedStats.reduce((res, {messages, segments, ...s}) => {
    const comboId = qs.stringify(
      Object.keys({...s})
        .filter((f) => groupBy.includes(f))
        .sort()
        //  FIXME: any
        .reduce((partial: any, key) => {
          partial[key] = {...s}[key as keyof typeof s];

          return partial;
        }, {})
    );
    const partialS = res[comboId];
    if (!partialS) {
      res[comboId] = {...s, messages, segments};
    } else {
      res[comboId] = {...s, messages: messages + partialS.messages, segments: segments + partialS.segments};
    }

    return res;
  }, {} as Dict<Statistic | ReportSenderIdStatistic>);

  return Object.values(groupedStatistics);
};
